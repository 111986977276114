// code originally from `tiny-invariant` package
// this mod/"fork" is to add the `console.error` of the full message in production
const isProduction: boolean = process.env.NODE_ENV === 'production';
const prefix = 'Invariant failed';

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
export default function invariant(
  condition: unknown,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string)
): asserts condition {
  if (condition) {
    return;
  }
  // Condition not passed

  // When not in production we allow the message to pass through
  const provided: string | undefined =
    typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided}` : prefix;

  // In production, we log the full message but throw a stripped down version
  const fullError = new Error(value);
  if (isProduction) {
    console.error(fullError);
    throw new Error(prefix);
  }

  throw fullError;
}
